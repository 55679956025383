define("driver-portal/locales/en-us/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "landing-page": {
      "features-block": {
        "heading": "Why drive with Bolt?",
        "car": {
          "title": "Be your own boss",
          "text": "Set your own schedule, get paid every week."
        },
        "prices": {
          "title": "Weekly payouts",
          "text": "Earn daily, get paid weekly. Check your earnings balance in the app."
        },
        "usage": {
          "title": "Easy to get started",
          "text": "Sign up and download the Bolt Driver app."
        }
      },
      "registered_company_name": "© Bolt Technology OÜ",
      "signup": "Signup",
      "cta_title": "Ready to ride?",
      "cta_description": "Get started in minutes!",
      "cta_button": "Sign up as a driver",
      "regstep1_signup_title": "Sign up to start driving",
      "regstep1_frequently_asked_questions": "Top questions from drivers",
      "regstep1_q_i_dont_drive_full-time_can_i_use_": "I don't drive full-time. Can I use Bolt for just a few hours a week?",
      "regstep1_a_of_course_some_of_our_drivers_are": "Of course, some of our drivers are only active a few hours during weekends and make solid part-time income.",
      "regstep1_q_how_do_i_get_paid": "How do I get paid?",
      "regstep1_a_clients_pay_you_in_cash_or_via_ta": "Clients pay you in cash or via Bolt. We pay out to your bank account every week.",
      "regstep1_q_how_much_do_i_pay_taxify": "How much do I pay Bolt?",
      "regstep1_a_we_do_not_have_any_monthly_fees_s": "We do not have any monthly fees, so you only pay for finished trips. We usually ask for 10% to 20% of the ride fee, depending on your city. You can get more information by contacting us.",
      "regstep1_q_how_long_does_it_take_to_sign_up": "How long does it take to sign up?",
      "regstep1_a_submitting_your_information_takes": "Submitting your information takes just 5 minutes. Following that, depending on the local office, you may receive a 30 minute in-person training. In some cities, we will accept your application remotely once you provide us with all the necessary documents.",
      "regstep1_q_what_if_i_dont_have_a_smartphone": "What if I don't have a smartphone?",
      "regstep1_a_we_can_help_you_rent_a_smartphone": "Our city teams suggest you a budget Android smartphone that you can buy or finance.",
      "regstep1_q_what_if_i_dont_have_a_car": "What if I don't have a vehicle?",
      "regstep1_a_we_can_help_you_rent_a_car_starti": "Our city teams can help you match with our partner fleets or rental companies who offer competitive rates on vehicles that are accepted on our platform. In some cities, longer term drivers can get financing deals from our partners.",
      "regstep1_contact_us_detail": "Office hours and contact details",
      "regstep1_business_entity_info": "Bolt Technology OÜ. Vana-Lõuna tn 39/1, Tallinn, 10134, Estonia",
      "regstep1_need_help": "Need Help?",
      "regstep1_need_help_detail": "Answers to popular questions",
      "phone_block": {
        "title": "Earn more with Bolt",
        "feature_1": {
          "title": "Low commission",
          "description": "Bolt charges a low commission fee."
        },
        "feature_2": {
          "title": "More customers",
          "description": "Receive lots of ride requests from all over the city, 24/7."
        }
      },
      "regstep1_alternative": {
        "subtitle": {
          "part1": "Earn extra money driving"
        }
      },
      "navbar": {
        "our-products": "Our products",
        "about-us": "About us",
        "links": {
          "legal": "Legal",
          "help-center": "Help Center",
          "ride": "Ride",
          "drivers": "Drive",
          "business": "Business",
          "food": "Food",
          "fleets": "Fleets",
          "scooters": "Scooters",
          "scootersPlatform": "Scooters Platform",
          "dispatcherSoftware": "Dispatch Software",
          "cities": "Cities",
          "careers": "Careers",
          "press": "Press",
          "blog": "Blog",
          "contacts": "Contacts"
        }
      }
    },
    "vehicle": {
      "color": {
        "white": "White",
        "black": "Black",
        "blue": "Blue",
        "green": "Green",
        "yellow": "Yellow",
        "red": "Red",
        "purple": "Purple",
        "gray": "Gray",
        "brown": "Brown",
        "beige": "Beige",
        "pink": "Pink",
        "orange": "Orange",
        "gold": "Gold",
        "silver": "Silver",
        "bronze": "Bronze",
        "maroon": "Maroon",
        "wine": "Wine",
        "wineRed": "Wine red",
        "skyBlue": "Sky blue",
        "lightBlue": "Light blue",
        "pearlWhite": "Pearl white",
        "pearl": "Pearl",
        "darkGrey": "Dark grey",
        "navyBlue": "Navy Blue"
      }
    },
    "tax_reminder": "The declaration of your income to the tax authorities is compulsory. Information relating to the tax regime and social regulations applicable to your income: {{{taxes_link}}}, {{{union_link}}}",
    "taxes": "taxes",
    "local_union": "local union",
    "taxify_driver_portal": "Bolt Driver Portal",
    "taxify_driver_portal__hopp": "Hopp Driver Portal",
    "yes": "Yes",
    "no": "No",
    "driver_username": "Driver username",
    "driver_username_or_email": "Email or username: {{{username}}}",
    "new_driver_login": "Driver username/email",
    "driver_username_placeholder": "Your driver username",
    "new_driver_login_placeholder": "Email or username",
    "new_driver_login_email_tab": "Email or username",
    "new_driver_login_phone_tab": "Phone number",
    "new_driver_via_text": "or via phone number",
    "password": "Password",
    "forgot_password": "Forgot Password",
    "logout": "Log Out",
    "bolt_app": {
      "install_bolt_app": "Install Bolt Driver app to start driving",
      "google_play_alt": "Download Bolt app in Google Play",
      "app_store_alt": "Download Bolt app in App Store",
      "huawei_app_gallery_alt": "Download Bolt app in Huawei App Gallery",
      "install_bolt_app__hopp": "Install Hopp Driver app to start driving",
      "google_play_alt__hopp": "Download Hopp app in Google Play",
      "app_store_alt__hopp": "Download Hopp app in App Store",
      "huawei_app_gallery_alt__hopp": "Download Hopp app in Huawei App Gallery"
    },
    "not_a_driver": "Not a Bolt driver?",
    "not_a_driver__hopp": "Not a Hopp driver?",
    "signup": "Signup here",
    "login": "Log In",
    "dont_have_password": "Don't have a password? Try to",
    "login_via_phone": "log in via phone",
    "code_sent_message": "A verification code was sent to {{{phone}}}",
    "continue": "Continue",
    "forgot_password_info_text": "Enter your email and phone, and we'll send you a link to reset your password.",
    "email": "Email",
    "phone": "Phone",
    "first_name": "First name",
    "last_name": "Last name",
    "reset_password": "Reset password",
    "enter_new_password": "Enter new password",
    "new_password": "New password",
    "new_password_again": "New password again",
    "please_enter_password": "Please enter password",
    "new_password_do_not_match": "New passwords do not match, please retry",
    "password_successfully_changed": "Password successfully changed.",
    "password_requirement_min_length": "{{min}} characters minimum",
    "password_requirement_mixed_case": "Contains both uppercase and lowercase letters",
    "password_requirement_number": "Contains numbers",
    "DRIVER_PASSWORD_TOO_SHORT_ERROR": "Password should contain at least {{min_chars}} characters!",
    "DRIVER_PASSWORD_TOO_WEAK_ERROR": "Password should contain numbers and letters with mixed case!",
    "DRIVER_PASSWORD_INVALID_ERROR": "Chosen password is easily guessed, please use something that is not related to you and what you have not used before and has mixed case letters and numbers.",
    "PASSWORD_RESET_REQUEST_NOT_FOUND": "Invalid password reset link.",
    "PASSWORD_RESET_REQUEST_IS_EXPIRED": "Sorry, but this link has expired!",
    "PASSWORD_RESET_REQUEST_DRIVER_NOT_UNIQUE": "Multiple drivers found, please contact customer support info@bolt.eu",
    "PASSWORD_RESET_REQUEST_DRIVER_NOT_UNIQUE__hopp": "Multiple drivers found, please contact customer support info@gethopp.com",
    "PASSWORD_RESET_REQUEST_EMAIL_IS_REQUIRED": "Email is required",
    "PASSWORD_RESET_REQUEST_SMS_LIMIT_REACHED": "Too many password reset attempts, please try again later.",
    "password_resend_email_success": "We have sent new password reset link to your email.",
    "password_resend_email_success_close_window": "You can now close this window.",
    "submit": "Submit",
    "profile": "Profile",
    "profile_info_text": "Here you can check your driver profile details and modify some if they are outdated.",
    "profile_details": "Driver details",
    "documents": "Driver documents",
    "eCashLoginKey": "eCash login key",
    "copy": "Copy",
    "copied": "Copied",
    "connect_to_halda": "Connect To Halda",
    "connect_to_halda_info": "To connect with Halda taximeters please copy the code to Halda self-portal",
    "my_documents": "My Documents",
    "my_documents_info_text": "Here are documents associated with your driver profile.",
    "no_documents_text": "No additional documents are required.",
    "documents_managed_by_fleet_owner_info_text": "Please contact your fleet owner or manager to update your documents.",
    "verified_document": "Verified document",
    "uploaded_document": "Waiting for verification",
    "declined_document": "Declined document",
    "actions": "Actions",
    "download": "Download",
    "document": {
      "filename": "Filename",
      "document": "Document",
      "expires": "Expires",
      "uploaded": "Uploaded",
      "expires_in_days": "Expires in {{days}} days",
      "expires_in_one_day": "Expires in 1 day",
      "is_expired": "Document is expired",
      "insurance_provider": "Insurance provider",
      "policy_number": "Policy or certificate number",
      "insurance_validity_updated_automatically": "Insurance validity updated automatically",
      "update_automatically": "Update my insurance validity automatically",
      "update_automatically_explanation_text": "Haven notifies Bolt automatically about your policy updates. This means that you only need to upload a new policy document again, when you’re issued a new certificate/supporting document due to a change in your details. To make this happen Bolt and Haven need to exchange some basic identification data about you and your car.",
      "update_automatically_explanation_text__hopp": "Haven notifies Hopp automatically about your policy updates. This means that you only need to upload a new policy document again, when you’re issued a new certificate/supporting document due to a change in your details. To make this happen Hopp and Haven need to exchange some basic identification data about you and your car.",
      "insurance_provider_haven": "Haven",
      "insurance_provider_other": "Other",
      "insurance_provider_empty": "",
      "expiry": {
        "year": "Year",
        "month": "Month",
        "day": "Day"
      },
      "errors": {
        "unable_to_set_insurance_provide_id_to_driver": "Unable to set insurance provider to driver",
        "documentNumberIsRequired": "Policy Number is required."
      }
    },
    "driver_licence_number": "Driver license number",
    "regstep3_driver_license_placeholder": "AB235235",
    "transport_licence_number": "Transport license number",
    "driver_licence_expires": "Driver licence expires",
    "transport_licence_expires": "Transport licence expires",
    "transport_operator_name": "Transport operator name",
    "transport_operator_number": "Transport operator number",
    "billing_information": "Billing information",
    "company_name": "Company name",
    "company_email": "Company email",
    "reg_code": "Reg. code",
    "vat_code": "VAT no.",
    "invoice_issuer_information": "Invoice issuer information",
    "invoice_issuer_information_info": "This information appears on invoices sent to your clients.",
    "legal_type": "Type",
    "invoice_issuer_name": "Name",
    "invoice_issuer_address": "Address",
    "invoice_issuer_reg_code": "Reg. code",
    "invoice_issuer_vat_code": "VAT no.",
    "business": "Business",
    "private": "Private",
    "account_information": "Payout account information",
    "account_recipient": "Account recipient’s first name and last name in Latin letters",
    "account_iban": "Bank account number",
    "account_swift": "Bank Name or BIC/SWIFT",
    "account_branch_code": "Transit number",
    "account_type": "Type",
    "cheque": "Cheque",
    "savings": "Savings",
    "confirm": "Confirm",
    "birthdate": "Date of birth",
    "country_of_birth": "Country of birth",
    "tax_information": "Tax information",
    "tax_identification_numbers": {
      "label": "Tax identification numbers",
      "add": "Add new row",
      "add_number": "Add number",
      "delete": "Delete",
      "explanation": "In certain markets, we are required to ask for your Tax Identification Number.",
      "read_more": "For more information, read more about <a href='https://bolt.eu/en-ee/support/articles/9123495527954/' target='blank'>Tax Identification Numbers</a>.",
      "first_add_button": "Add Tax Identification Number"
    },
    "profile_disabled_field_help_message_v2": "To update, please contact our support team via the app",
    "message_empty": "",
    "profile_saved_success_message": "Changes saved",
    "profile_saved_failed_message": "Failed to save changes. Some fields are invalid.",
    "NOT_AUTHORIZED": "Unauthorized request",
    "DRIVER_PROFILE_UPDATE_REQUEST_NOT_FOUND": "Profile update request not found",
    "DRIVER_PROFILE_REQUEST_NOTHING_TO_UPDATE": "There was nothing to update",
    "DRIVER_PROFILE_PHONE_NUMBER_COUNTRY_MISMATCH_REGISTRATION": "Use a phone number from your registration country.",
    "DRIVER_PROFILE_DRIVER_HAS_ORDER": "You have an active order in Bolt Driver app, please finish it before updating the profile.",
    "DRIVER_PROFILE_DRIVER_HAS_ORDER__hopp": "You have an active order in Hopp Driver app, please finish it before updating the profile.",
    "TWO_STEP_VERIFICATION_CODE_NOT_FOUND": "Verification code not found",
    "TWO_STEP_VERIFICATION_CODE_SEND_LIMIT_REACHED": "Too many verification attempts, please try again later.",
    "TWO_STEP_VERIFICATION_CODE_HAS_EXPIRED": "Verification code has expired",
    "TWO_STEP_VERIFICATION_CODE_ATTEMPTS_LIMIT_REACHED": "Too many confirm attempts",
    "TWO_STEP_VERIFICATION_CODE_NOT_CONFIRMED": "Verification code not confirmed",
    "TWO_STEP_VERIFICATION_CODE_IN_WRONG_STATE": "Verification code already used",
    "profile_successfully_changed": "Profile updated",
    "help": "Help",
    "address": "Address",
    "display_name": "Display name",
    "birthday": "Birthday",
    "name": "Name",
    "back": "Back",
    "home": "Home",
    "ok": "Ok",
    "error": "Error",
    "password_reset_link_email_sent": "We have sent a password reset link to your email {{{email}}}.",
    "DRIVER_NOT_FOUND": "Driver account not found, check if you have entered proper details.",
    "DRIVER_LOCKOUT": "Too many failed attempts. Try logging in with your phone number or requesting a login link.",
    "DRIVER_WRONG_PASSWORD": "Invalid password",
    "DRIVER_NOT_APPROVED": "Driver account not approved",
    "REFRESH_TOKEN_INVALID": "Login token expired. Please try again.",
    "SESSION_EXPIRED": "Session expired, please log in.",
    "driver_app_tutorial_videos": "Driver App tutorial videos",
    "failed_to_contact_servers": "Failed to contact servers, please try again later.",
    "display_image": "Display image",
    "display_image_alt_text": "Display image, visible to clients",
    "display_image_info": "This picture is visible to clients.",
    "display_image_change_link": "Contact us to change image.",
    "fiscalization_token_info": "To enable to your fiscalization, please copy this key to your Virtual Cash Register App.",
    "profile_documents_info": "Driver and taxi license details. Keep an eye on expiration dates.",
    "profile_billing_information_info": "This information appears on invoices sent to you and your clients.",
    "contacts": "Contacts",
    "driver_training_video": "Driver training video",
    "driver_apps": "Driver apps",
    "best_practices_slides": "Best practices slides",
    "driver_app_guides": "Driver app guides",
    "driver_app_tutorial": "Driver App Step-by-Step tutorial",
    "driver_app_installation_guide_tutorial": "Driver App installation guide",
    "driver_app_tutorial_description": "read this before starting so you're familiar with process.",
    "driver_app_installation_guide_tutorial_description": "read this if you are stuck during installation process (Android).",
    "driver_app_guides_info": "guides below will help you get familiar with the app and answer questions you may have:",
    "driver_app_update_guide": "Driver App Update guide",
    "driver_app_update_guide_description": "covers how to update your Android Driver app.",
    "help_page_info": "At Bolt, our biggest focus is on exceptional customer service. When clients are using Bolt to book rides, they expect high level of service. Get familiar with our driver resources below, follow the advice and you will have high driver rating.",
    "help_page_info__hopp": "At Hopp, our biggest focus is on exceptional customer service. When clients are using Hopp to book rides, they expect high level of service. Get familiar with our driver resources below, follow the advice and you will have high driver rating.",
    "android_app": "Android App",
    "iphone_app": "iPhone App",
    "resources": "Resources",
    "guides_and_faq": "Guides & FAQ",
    "faq_and_support": " FAQ & Support",
    "driver_faq": "Driver FAQ",
    "driver_faq_link_text": "Visit Bolt Knowledge base",
    "driver_faq_link_text__hopp": "Visit Hopp Knowledge base",
    "driver_faq_info_text": "Get answers to most questions you may have for Bolt, or submit your own.",
    "driver_faq_info_text__hopp": "Get answers to most questions you may have for Hopp, or submit your own.",
    "password_reset_phone_input_tip": "Enter your full phone number. E.g. +37211111111",
    "password_reset_phone_input_tip_dynamic": "Select international country prefix and enter your phone number. For example select +{{prefix}} and enter {{phone}}",
    "phone_sanitizing_info": "Phone number will be cleaned: spaces, brackets and dashes will be removed",
    "DRIVER_REGISTRATION_LOG_NOT_FOUND": "Invalid link",
    "INVITATION_NOT_FOUND": "Invalid link",
    "DRIVER_REGISTRATION_FORM_INVALID_SET_OF_RULES": "Invalid link",
    "DRIVER_REGISTRATION_FORM_DATA_MISSING": "Server error, missing form data",
    "DRIVER_REGISTRATION_FORM_VALIDATION_FAILED": "Server error, documents validation failed",
    "DRIVER_REGISTRATION_LOG_IN_INVALID_STATE": "Server error, registration application has invalid state",
    "invalid_email": "Please enter a valid email address.",
    "invalid_phone_number": "Please enter a valid phone number in international format, including \"+\" and country calling code.",
    "email_is_taken": "This email is already taken.",
    "email_is_too_long": "Email is too long",
    "terms_consent_is_mandatory": "You must agree to continue.",
    "first_name_is_mandatory": "First name is mandatory.",
    "first_name_is_too_long": "First name is too long.",
    "last_name_is_mandatory": "Last name is mandatory.",
    "last_name_is_too_long": "Last name is too long.",
    "city_is_mandatory": "City is mandatory.",
    "language_is_mandatory": "Language is mandatory.",
    "model_is_mandatory": "Vehicle make is mandatory.",
    "invalid_car_year": "Vehicle year is invalid.",
    "license_plate_mandatory": "License plate is mandatory.",
    "license_plate_is_too_long": "License plate is too long.",
    "normalized_license_plate_mandatory": "Error when processing licence plate number. Please check that it is entered correctly and doesn't contain unsupported symbols.",
    "normalized_license_plate_is_too_long": "Error when processing licence plate number. Please check that it is entered correctly and of appropriate format.",
    "car_vin_mandatory": "Vehicle vin is mandatory.",
    "car_vin_invalid_format": "Vehicle vin needs to be 17 characters.",
    "car_transport_licence_number_is_mandatory": "Vehicle transport licence number is mandatory.",
    "car_transport_licence_number_invalid_format": "Vehicle transport licence number format is invalid.",
    "car_color_mandatory": "Vehicle color is mandatory.",
    "national_id_is_mandatory": "National ID is mandatory.",
    "national_id_is_too_long": "National ID is too long.",
    "driver_license_is_mandatory": "Driver license is mandatory.",
    "taxi_license_is_mandatory": "Taxi license is mandatory.",
    "taxi_license_is_too_long": "Taxi license is too long.",
    "beneficiary_name_is_mandatory": "Beneficiary name is mandatory.",
    "beneficiary_name_is_too_long": "Beneficiary name is too long.",
    "beneficiary_name_invalid_format": "Beneficiary name can only contain Latin characters.",
    "swift_is_mandatory": "Bank Name or BIC/SWIFT is mandatory.",
    "swift_is_too_long": "Bank Name or BIC/SWIFT is too long.",
    "bank_account_number_is_mandatory": "Bank account number is mandatory.",
    "bank_account_number_is_too_long": "Bank account number is too long, maximum length is 12 characters.",
    "company_name_is_mandatory": "Company name is mandatory.",
    "company_name_is_too_long": "Company name is too long.",
    "address_is_mandatory": "Address is mandatory.",
    "address_is_too_long": "Address is too long.",
    "company_is_liable_to_vat_is_mandatory": "VAT liable is mandatory to check",
    "reg_code_is_mandatory": "Registration code is mandatory.",
    "reg_code_is_too_long": "Registration code is too long.",
    "vat_code_is_mandatory": "VAT number is mandatory.",
    "vat_code_is_too_long": "VAT number is too long.",
    "invalid_type": "Billing type is invalid.",
    "invalid_iban": "IBAN is invalid.",
    "invalid_clabe": "CLABE is invalid.",
    "invalid_referral_code": "Referral code is too long, maximum length is 45 characters.",
    "invalid_driver_license": "Driver license is too long, maximum length is 20 characters.",
    "birthday_is_mandatory": "Birthday is mandatory.",
    "invalid_birthday": "Birthday is invalid.",
    "username_is_taken": "Username is taken",
    "password_is_invalid": "Password should contain capital letters and numbers!",
    "error_registration_finished": "Your application is finished",
    "DRIVER_REGISTRATION_LOG_NOT_IN_PROGRESS": "You can't change the details you submitted anymore. In case you had wrong data, please contact us.",
    "finish": "Finish",
    "former_company": "Formerly Taxify",
    "regstep1_will_be_username": "This will be your username.",
    "regstep1_phone_verification_hint": "You will receive a confirmation code to this number",
    "regstep1_signup_facebook_button_text": "Sign up on<div class='subtitle'>Facebook</div>",
    "regstep1_signup_title": "Signup as a driver below",
    "regstep1_signup_title_new": "Sign up as a driver",
    "regstep1_signup_description_part1": "If you have multiple vehicles and drivers",
    "regstep1_signup_description_part1_new": "If you have access to multiple vehicles and/or drivers",
    "regstep1_signup_description_part2": "signup as a fleet owner here",
    "regstep1_choose_a_city": "Choose a city",
    "regstep1_drive_with_taxify": "Drive with Bolt",
    "regstep1_earn_up_to": "Earn good money",
    "regstep1_15_an_hour": "with your vehicle.",
    "regstep1_email_placeholder": "john.doe@gmail.com",
    "regstep1_email_placeholder_new": "Enter your email",
    "regstep1_phone_placeholder": "51112345",
    "regstep1_i_have_a_car": "I have a car",
    "regstep1_i_need_a_car": "I need a car",
    "regstep1_next": "Next",
    "regstep1_continue": "Continue",
    "regstep1_welcome_back": "Welcome back, {{fullname}}!",
    "regstep1_welcome_back_not_this_person": "Not You? Sign up now",
    "regstep1_welcome_back_lead": "Would you like to continue your registration?",
    "regstep1_by_signing_up_you_accept_our": "By signing up, you accept our",
    "regstep1_terms_consent": "By signing up, you accept our {{{terms_of_service_link}}} and {{{privacy_policy_link}}}.",
    "regstep1_terms_consent_link_terms_of_service": "Terms of Service",
    "regstep1_terms_consent_link_privacy_policy": "Privacy Policy",
    "regstep1_available_for": "Available for",
    "regstep1_android": "Android",
    "regstep1_iphone": "iPhone",
    "regstep1_why_taxify": "Why Bolt?",
    "regstep1_earn_more": "Earn money",
    "regstep1_taxify_helps_drivers_get_more_booki": "Drive with Bolt and earn extra income.",
    "regstep1_drive_anytime": "Drive anytime",
    "regstep1_work_with_your_own_schedule_no_mini": "Work with your own schedule. No minimum hours and no boss.",
    "regstep1_no_monthly_fees": "No monthly fees",
    "regstep1_no_risk_you_only_pay_for_finished_t": "No risk, you only pay when you earn.",
    "regstep1_how_taxify_works": "How Bolt works?",
    "regstep1_1_accept_the_request": "Accept the request",
    "regstep1_1_alt_text": "Get ride requests",
    "regstep1_2_pickup_the_client": "Pickup the client",
    "regstep1_2_alt_text": "Pick up the passenger",
    "regstep1_3_drive_to_destination": "Drive to destination",
    "regstep1_3_alt_text": "Take the passenger to destination",
    "regstep1_frequently_asked_questions": "Frequently Asked Questions",
    "regstep1_q_i_dont_drive_full-time_can_i_use_": "Q: I don't drive full-time. Can I use Bolt for just a few hours a week?",
    "regstep1_a_of_course_some_of_our_drivers_are": "A: Of course, some of our drivers are only active a few hours during weekends and make solid part-time income.",
    "regstep1_q_how_do_i_get_paid": "Q: How do I get paid?",
    "regstep1_a_clients_pay_you_in_cash_or_via_ta": "A: Clients pay you in cash or via Bolt. We pay out to your bank account every week.",
    "regstep1_q_how_much_do_i_pay_taxify": "Q: How much do I pay Bolt?",
    "regstep1_a_we_do_not_have_any_monthly_fees_s": "A: We do not have any monthly fees, so you only pay for finished trips. We usually ask for 10% to 20% of the ride fee, depending on your city. You can get more information by contacting us.",
    "regstep1_q_how_long_does_it_take_to_sign_up": "Q: How long does it take to sign up?",
    "regstep1_a_submitting_your_information_takes": "A: Submitting your information takes just 5 minutes. Following that, depending on the local office, you may receive a 30 minute in-person training. In some cities, we will accept your application remotely once you provide us with all the necessary documents.",
    "regstep1_q_what_if_i_dont_have_a_smartphone": "Q: What if I don't have a smartphone?",
    "regstep1_a_we_can_help_you_rent_a_smartphone": "A: Our city teams suggest you a budget Android smartphone that you can buy or finance.",
    "regstep1_q_what_if_i_dont_have_a_car": "Q: What if I don't have a vehicle?",
    "regstep1_a_we_can_help_you_rent_a_car_starti": "A: Our city teams can help you match with our partner fleets or rental companies who offer competitive rates on vehicles that are accepted on our platform. In some cities, longer term drivers can get financing deals from our partners.",
    "regstep1_check_our_knowledgebase_for_more_in": "Check our Knowledgebase for more info.",
    "regstep1_sign_up_to_drive_with_taxify": "Sign up to drive with Bolt",
    "regstep1_got_questions_or_concerns": "Got questions or concerns?",
    "regstep1_contact_us": "Contact us.",
    "regstep1_business_entity_info": "Taxify OÜ. Vana-Lõuna tn 39/1, Tallinn, 10134, Estonia",
    "regstep1_phone_number_invalid": "Please select your countries international phone prefix and enter your phone number. For example select +{{prefix}} and enter {{phone}}",
    "regstep1_whatsapp_opt_in_checkbox": "Opt-in for helpful updates from Bolt via WhatsApp.",
    "regstep1": {
      "error-page": {
        "title": "Connection failure",
        "p1": "There seems to be a problem connecting to our service.",
        "p2": "Please try again later or switch to a better network connection.",
        "retry-btn": "Retry"
      },
      "commute": {
        "title": "Commute with Bolt",
        "subtitle": "Earn back your vehicle expenses",
        "form": {
          "title": "Signup as commute driver below"
        }
      }
    },
    "regstep1_alternative": {
      "title": "Drive with Bolt",
      "subtitle": {
        "part1": "Earn extra money driving.",
        "part2": "Work on your own schedule and get paid weekly."
      },
      "cta": "Sign up to drive with Bolt",
      "benefits": {
        "title": "Why Bolt?",
        "1": {
          "title": "Earn more money",
          "description": "Earn more driving with Bolt due to lower commission and extra bonuses. No hidden fees.",
          "alt": "Earn extra money driving with Bolt"
        },
        "2": {
          "title": "Drive anytime",
          "description": "Work whenever you want and have more freedom and flexibility. Receive your earnings weekly.",
          "alt": "Work on your own schedule with the Bolt Driver app"
        },
        "3": {
          "title": "Get started easily",
          "description": "All you need is a smartphone and Bolt Driver app. Our support is always available to help you.",
          "alt": "Start working by downloading Bolt Driver app"
        }
      },
      "guide": {
        "title": "How Bolt Works?",
        "alt": "Driving with Bolt",
        "step1": "Accept a ride request",
        "step2": "Pick up the passenger",
        "step3": "Drive to destination",
        "step4": "Collect your earnings"
      },
      "faq": {
        "title": "Frequently Asked Questions",
        "knowledge_base": {
          "text": "For more information, check out our",
          "cta": "Knowledge Base"
        },
        "1": {
          "question": "I don't drive full-time. Can I use Bolt for just a few hours a week?",
          "answer": "Of course, some of our drivers are only active a few hours during weekends and make solid part-time income."
        },
        "2": {
          "question": "How do I get paid?",
          "answer": "Clients pay you in cash or via Bolt. We pay out to your bank account every week."
        },
        "3": {
          "question": "How much do I pay Bolt?",
          "answer": "We do not have any monthly fees, so you only pay for finished trips. We usually ask for 10% to 20% of the ride fee, depending on your city. You can get more information by contacting us."
        },
        "4": {
          "question": "How long does it take to sign up?",
          "answer": "Submitting your information takes just 5 minutes. Following that, depending on the local office, you may receive a 30 minute in-person training. In some cities, we will accept your application remotely once you provide us with all the necessary documents."
        },
        "5": {
          "question": "What if I don't have a smartphone?",
          "answer": "Our city teams suggest you a budget Android smartphone that you can buy or finance."
        },
        "6": {
          "question": "What if I don't have a vehicle?",
          "answer": "Our city teams can help you match with our partner fleets or rental companies who offer competitive rates on vehicles that are accepted on our platform. In some cities, longer term drivers can get financing deals from our partners."
        }
      },
      "contact_us": {
        "text": "Got questions or concerns?",
        "cta": "Contact Us"
      }
    },
    "progress_contacts": "Contacts",
    "progress_personal_n_car_info": "Personal & vehicle info",
    "progress_documents_n_fees": "Documents & fees",
    "progress_documents": "Documents",
    "progress_payment_details": "Payment details",
    "regstep2_personal_information_and_car_detail": "Personal information and vehicle details",
    "regstep2_let_us_know_your_name_and_some_info": "Only your first name and vehicle details are visible to clients during the booking",
    "regstep2_first_name_placeholder": "First name",
    "regstep2_last_name_placeholder": "Last name",
    "regstep2_city": "City",
    "regstep2_language": "Language",
    "regstep2_referral_code": "Referral code",
    "regstep2_referral_code_placeholder": "Referral code",
    "regstep2_if_someone_referred_you_enter_their": "If someone referred you, enter their code",
    "regstep2_i_have_a_car": "I have a vehicle that I will drive.",
    "regstep2_fleet_matching_consent": "<b>I would like to find a Fleet Partner</b><br/>By checking the box you agree that your name, phone number and e-mail will be provided to our Fleet Partners to contact you for cooperation opportunities. You can withdraw your consent at any time.",
    "regstep2_fleet_matching_consent_v2": "<b>I would like to be matched with a Fleet Partner</b><br/>By checking the box you agree that your information submitted in the registration process will be provided to our Fleet Partners for matching and enrolment purposes. You can withdraw your consent at any time.",
    "regstep2_have_multiple_cars_": "Have multiple vehicles?",
    "regstep2_sign_up_as_a_fleet_owner": "Sign up as a fleet owner",
    "regstep2_car_manufacturer": "Vehicle manufacturer",
    "regstep2_vehicle_type": "Vehicle type",
    "car": "Car",
    "motorbike": "Motorbike",
    "regstep2_car_make": "Vehicle model",
    "regstep2_car_year": "Vehicle year",
    "regstep2_car_color": "Vehicle color",
    "regstep2_car_model_not_in_list": "If you don't find your vehicle model from list then let us know at {{email}}",
    "regstep2_license_plate": "License plate",
    "regstep2_license_plate_placeholder": "717 TTP",
    "regstep2_vin": "Vin",
    "regstep2_vin_placeholder": "4UZACJDC36CX71366",
    "regstep2_car_transport_licence_number": "Vehicle transport licence number",
    "regstep2_car_transport_licence_number_placeholder": "Vehicle transport licence number",
    "regstep2_next": "Next",
    "regstep3_legal_and_pricing_details": "Legal and pricing details",
    "regstep4_documents": "Documents",
    "regstep3_we_need_your_social_security_number": "Your national ID and license details will be kept private.",
    "regstep3_national_id": "National ID",
    "regstep3_ssn_placeholder": "38809036666",
    "regstep3_your_social_security_number_or_coun": "Your social security number or country's alternative (e.g. BVN)",
    "regstep3_driver_license": "Driver license",
    "regstep3_birthday": "Birthday",
    "regstep3_year": "Year",
    "regstep3_month": "Month",
    "regstep3_day": "Day",
    "regstep3_license_number_on_your_drivers_docu": "License number on your Driver's documents",
    "regstep3_no_taxi_license": "I don't have a taxi license",
    "regstep3_taxi_license": "Taxi license",
    "regstep3_taxi_license_placeholder": "TA012345",
    "regstep3_taxi_drivers_professional_certifica": "Taxi driver's professional certification number",
    "regstep3_if_you_have_multiple_6_passengers_u": "If you have multiple tariffs, use higher",
    "regstep3_continue": "Continue",
    "regstep3_license_type": "License type",
    "regstep3_private_hire": "Private Hire Driver (PHL license)",
    "regstep3_hackney_carriage": "Taxi Driver (Hackney Carriage licence)",
    "regstep3_private_driver": "Private Driver",
    "regstep3_taxi_driver": "Taxi Driver",
    "regstep3_unknown": "I don't know",
    "regstep4_payment_details": "Payment details",
    "regstep4_we_need_your_payment_details_to_sta": "We need your payment details to pay you.",
    "regstep4_billing_type": "Billing type",
    "regstep4_company": "Company",
    "regstep4_person": "Person",
    "regstep4_other": "Other",
    "regstep4_company_name": "Company name",
    "regstep4_company_name_placeholder": "ABC Taxis Ltd",
    "regstep4_full_legal_company_name": "Full, legal company name",
    "regstep4_address": "Address",
    "regstep4_address_placeholder": "Vana-Lõuna tn 39/1, Tallinn, 10134",
    "regstep4_vat_liability": "VAT liability",
    "regstep4_company_is_vat_liable": "VAT liable",
    "regstep4_registration_code": "Registration code",
    "regstep4_registration_code_placeholder": "12417834",
    "regstep4_companys_registration_code": "Company's registration code",
    "regstep4_registration_code_ua": "Tax ID",
    "regstep4_registration_code_placeholder_ua": "123456789",
    "regstep4_registration_code_help_ua": "Tax ID",
    "regstep4_vat_number": "VAT number",
    "regstep4_vat_number_placeholder": "67892",
    "regstep4_beneficiary_name": "Bank account holder name",
    "regstep4_beneficiary_placeholder": "ABC Taxis Ltd / John Doe",
    "regstep4_bank_account_holder_name_person_or_": "Bank account holder name, person or company",
    "regstep4_iban": "Bank account number",
    "regstep4_iban_placeholder": "EE38 2200 2210 2014 5685",
    "regstep4_bank_card": "Bank Card",
    "regstep4_bank_card_placeholder": "Bank Card Number",
    "regstep4_payment_phone": "Phone",
    "regstep4_payment_phone_placeholder": "+3721234567",
    "regstep4_your_bank_account_number_in_iban_fo": "Your bank account number, in IBAN or other format",
    "regstep4_bicswift": "Bank Name or BIC/SWIFT",
    "regstep4_swift_placeholder": "HABALT22",
    "regstep4_branch_code": "Transit number",
    "regstep4_branch_code_placeholder": "12345",
    "regstep4_bank_name": "Bank name",
    "regstep4_bank_name_other": "Other",
    "regstep4_if_unknown_use_banks_name": "If unknown, use bank's name",
    "regstep4_continue": "Finish",
    "regstep6_success": "Success!",
    "regstep6_expect_to_be_contacted_by_taxify_in": "Expect to be contacted by Bolt in a few days.",
    "regstep6_expect_to_be_contacted_by_taxify_in__hopp": "Expect to be contacted by Hopp in a few days.",
    "regstep6_remember_to_add_bolt_as_authorized": "<b>Important: </b>Please remember to allow Bolt (NIF: N0531372A) to report rides information to local authorities. The required steps must be completed in the {{{ministery_link}}} website. This is a legal requirement.",
    "regstep6_transport_ministery": "Transport Ministery",
    "regstep6_meanwhile_check_out_short": "Meanwhile, check our short Driver's training video:",
    "regstep6_now_meet_our_drivers_portal": "Now, meet our driver's portal:",
    "regstep6_go_to_drivers_portal": "Go to Driver's Portal",
    "driver_account_approved_title": "Your driver account is approved",
    "driver_account_approved_description": "Now you can log in as a driver. The app might ask for some permissions required for its correct work.",
    "driver_account_approved_login_as_driver": "Log in as driver",
    "password_is_weak": "Password should contain capital letters and numbers!",
    "password_is_short": "Password should be at least 6 character!",
    "errorpage_error": "Error:",
    "errorpage_maybe_you_were_looking_for": "Maybe you were looking for:",
    "errorpage_taxify_driver_registration": "Bolt driver registration",
    "errorpage_taxify_driver_registration__hopp": "Hopp driver registration",
    "errorpage_driver_portal_login": "Driver portal login",
    "errorpage_driver_apps": "Driver apps:",
    "errorpage_for_android_in_google_play": "For Android in Google Play",
    "errorpage_for_iphone_in_app_store": "For iPhone in App Store",
    "errorpage_contacts": "Contacts",
    "taxify_partners": "Bolt Partners",
    "taxify_partners__hopp": "Hopp Partners",
    "regstep1_meta_title": "Sign Up as a Bolt Driver | Bolt",
    "regstep1_meta_description": "Earn extra money as a Bolt driver. Reliable earnings. No minimum hours or monthly fees! Drive part-time or whenever you want. Apply today.",
    "log_out_confirmation_question": "Are you sure you want to log out?",
    "retry": "Retry",
    "loading_city_data_failed": "Loading city data failed.",
    "invoices": "Invoices",
    "invoices_info_text": "Here you can see the list of invoices issued to you by Bolt.",
    "invoices_info_text__hopp": "Here you can see the list of invoices issued to you by Hopp.",
    "invoices_info_text_uk": "Here you can see the list of invoices issued from you to Bolt.",
    "invoice_no": "Invoice no",
    "date": "Date",
    "due_date": "Due date",
    "total_sum": "Total sum",
    "sum": "Sum",
    "pdf": "PDF",
    "unable_to_receive_pdf": "Unable to receive PDF",
    "unable_to_receive_csv": "Unable to receive CSV",
    "no_invoices_text": "No invoices have been issued yet.",
    "compensations": "Compensations",
    "compensations_info_text": "Here you can see the list of compensations provided by Bolt.",
    "compensations_info_text__hopp": "Here you can see the list of compensations provided by Hopp.",
    "compensations_info_text_uk": "From July 1st 2022, Bolt contracts directly with passengers rather than drivers. This will also change how you see invoices. Bolt will issue invoices to the passengers for the trips, and you will issue invoice to Bolt for the individual. Hence, you will see only trip invoices to passengers issued prior to July 1st.",
    "description": "Description",
    "no_compensations_text": "No compensations have been issued yet.",
    "my_rides": "My Rides",
    "vehicles": "Vehicles",
    "rider_invoices": "Rider Invoices",
    "payouts": {
      "title": "Payouts",
      "description": "Here you can see your payouts.",
      "fields": {
        "amount": "Amount",
        "paid-out": "Paid out"
      },
      "messages": {
        "no-payouts": "No payouts have been issued yet."
      }
    },
    "extendable_content": {
      "show_more": "Show more",
      "show_less": "Show less"
    },
    "pension": {
      "eligibility_alert": {
        "title": "Unfortunately, you are not eligible for the pension program for the following reasons:",
        "reasons": {
          "age": "You must be between 18 and 74 years of age to qualify."
        }
      },
      "missing_profile_data_message": "Required profile data is missing from your profile. Please contact support for assistance.",
      "title": "Pension Program",
      "description": "Fill in the form and click “Enrol” to enrol for the pension program.",
      "personal_data": "Personal data",
      "middle_name": "Middle name (Optional)",
      "legal_gender": "Legal gender",
      "personal_title": "Personal title",
      "partner_information": "Partner information",
      "partner_reference": "Partner reference",
      "nin": "National insurance number",
      "date_joined_bolt": "Date joined Bolt",
      "personal_title_options": {
        "Mr": "Mr",
        "Mrs": "Mrs",
        "Ms": "Ms",
        "Miss": "Miss",
        "Dr": "Dr",
        "Prof": "Prof",
        "Other": "Other"
      },
      "your_title": "Your title",
      "gender_options": {
        "male": "Male",
        "female": "Female"
      },
      "contacts": "Contacts",
      "address": {
        "line_1": "Address - Line 1",
        "line_2": "Address - Line 2 (Optional)",
        "line_3": "Town / City",
        "line_4": "County (Optional)",
        "postcode": "Postcode"
      },
      "validation": {
        "personal_title": {
          "is_required": "Personal title is mandatory",
          "not_matching_gender": "Personal title does not match gender"
        },
        "gender": {
          "is_required": "Gender is mandatory"
        },
        "address": {
          "line_1": {
            "is_required": "Address - Line 1 is mandatory",
            "too_long": "Address - Line 1 is too long"
          },
          "line_2": {
            "too_long": "Address - Line 2 is too long"
          },
          "line_3": {
            "is_required": "Town / City is mandatory",
            "too_long": "Town / City is too long"
          },
          "line_4": {
            "too_long": "County is too long"
          },
          "postcode": {
            "invalid": "Postcode is invalid",
            "too_long": "Postcode is too long"
          }
        },
        "email": {
          "invalid": "Email is invalid",
          "too_long": "Email is too long"
        },
        "phone": {
          "invalid": "Phone is invalid",
          "too_long": "Phone is too long"
        },
        "enrolment_failed": "Failed to enrol. Some fields are invalid."
      },
      "enrolment_failed": "Something went wrong. Please try again later.",
      "country_of_residence": "Country of residence",
      "enrol_button": "Enrol",
      "pause_button": "Pause the program",
      "continue_button": "Continue contribution",
      "pension_declaration_consent": "I declare that I have carefully read and understood the Declaration and acknowledge my full agreement with the contents of the Declaration.",
      "enrolment_submitted_message": "The enrollment request has been submitted, and the enrollment will become active starting next month.",
      "enrolment_will_be_active_message": "The enrollment request has been submitted, and enrollment will be effective as of {{date}}.",
      "enrolment_active_message": "You're enrolled to the program and contributing since {{date}}.",
      "enrolment_paused_message": "Your contributions will be temporarily paused as of {{date}}. If not resumed within a year, the program will be permanently terminated.",
      "pause_confirmation_dialog": {
        "title": "Pause your contributions?",
        "text": "Your contributions will be paused and ultimately terminated in a year if you do not resume them within this timeframe.",
        "pause_button": "Pause contribution",
        "close_button": "Close"
      },
      "declaration": {
        "important_statement": "<strong>Important:</strong> before proceeding, please read the following documents. You can find these on the Aviva <a href='https://workplace.aviva.co.uk/bolt-drivers/' target='_blank' rel='noopener noreferrer'>website</a> under <strong>Documents</strong>",
        "documents": "Documents",
        "ordered_list": {
          "l1": "Key features document",
          "l2": "Terms and conditions",
          "l3": "Fees and Charges brochure",
          "l4": "Illustration",
          "l5": "Bolt’s Pension Terms and Conditions"
        },
        "get_in_touch": "Please get in touch with Aviva if you’d prefer the documents in large font, braille or as audio.",
        "declaration": "Declaration",
        "serious_offence": "Important: it’s a serious offence to make false statements. If you do so you may be prosecuted. The penalties are severe.",
        "agreement": {
          "l1": "I apply to join the Bolt Drivers Pension Plan with Aviva.  This is a Corporate Self Invested Personal Pension Plan and I agree to be bound by the rules of the plan and Bolt’s Pension Terms and Conditions.",
          "l2": "I authorise Bolt to deduct 5% from the Driver payments and pay them to Aviva.",
          "l3": "I authorise Bolt to submit personal information to Aviva for the purpose of joining me into the Bolt Drivers Pension Plan.",
          "l4": "I understand that I can only make payments (contributions) which are eligible for tax relief and that any contributions which aren’t eligible for tax relief will be refunded.",
          "l5": "I declare that I’m a relevant UK individual.",
          "l6": "I declare that I have been given Terms and Conditions of the plan (these are Aviva’s standard terms and conditions which they intend to rely on. For your own benefit and protection, you should read these carefully. If you don’t understand anything, please ask Aviva for further information).",
          "l7": "I declare that I will tell Aviva if there are any changes to the information I’ve given to Bolt, or should have given, before the policy starts. I understand that failure to do so may result in the contract being declared void and that a claim for the proceeds may not be paid.",
          "l8": "I declare that if I’ve flexibly accessed money purchase pension savings, it’s my responsibility to inform Aviva of the date this occurred, and I understand that a reduced annual allowance applies.",
          "l9": "I understand that by accepting Aviva’s standard Terms and Conditions and enrolling into the pension, these contain standard references to “Employer” and “Employee” and “salaries” which are not consistent with my status or the relevant payment basis."
        },
        "statement": "Please note that the Aviva T&Cs and Aviva Fees and Charges Brochure linked above are for information purposes only. You will be required to enter into a separate agreement with Aviva and Aviva holds sole responsibility for notifying you about any modifications or updates to their Terms and Conditions or Fees and Charges Brochure. You are advised to regularly check Aviva's website for any supplementary information or changes pertaining to your agreement with Aviva. Any concerns or queries regarding your agreement with Aviva should be directed to Aviva's Customer Service or other support channels."
      }
    },
    "rider_invoices_lead": "Invoices that have been issued to riders from you.",
    "rider_invoices_lead_uk": "From July 1st 2022, Bolt contracts directly with passengers rather than drivers. This will also change how you see invoices. Bolt will issue invoices to the passengers for the trips, and you will issue invoice to Bolt for the individual. Hence, you will see only trip invoices to passengers issued prior to July 1st.",
    "order_time": "Order time",
    "pickup_address": "Pickup address",
    "ride_price": "Ride price",
    "payment_method": "Payment method",
    "cash": "Cash",
    "card": "Card Terminal",
    "app_payment": "Bolt Payment",
    "app_payment__hopp": "Hopp Payment",
    "business_payment": "Bolt Business",
    "business_payment__hopp": "Hopp Business",
    "payment_time": "Payment time",
    "invoice_number": "Invoice Number",
    "date_of_ride": "Date of ride",
    "invoice_receipient_name": "Recipient",
    "invoice_receipient_address": "Recipient Address",
    "invoice_receipient_reg_code": "Recipient Registration Number",
    "invoice_receipient_vat_code": "Recipient NIM / VAT Number",
    "driver_company_name": "Company name (Driver)",
    "driver_company_address": "Company address (Street, Number, ZIP, Country)",
    "driver_company_reg_number": "Company Registration Number",
    "vat_number": "Company NIM / VAT number",
    "price_no_vat": "Price (no VAT)",
    "vat": "VAT",
    "price_total": "Price Total",
    "no_rides_text": "No rides found.",
    "month_name_0": "January",
    "month_name_1": "February",
    "month_name_2": "March",
    "month_name_3": "April",
    "month_name_4": "May",
    "month_name_5": "June",
    "month_name_6": "July",
    "month_name_7": "August",
    "month_name_8": "September",
    "month_name_9": "October",
    "month_name_10": "November",
    "month_name_11": "December",
    "file_size_limit": "File size exceeds {{data.sizeLimit}} MB",
    "all_payment_methods": "All payment methods",
    "download_pdf": "Download PDF",
    "download_csv": "Download CSV",
    "FILE_SIZE_OVER_LIMIT": "File size over the limit.",
    "FILE_SIZE_EMPTY": "Empty files are not allowed.",
    "INVALID_FILE_EXTENSION": "Invalid file.",
    "INVALID_FILE_EXTENSION_SUPPORTED_EXT": "Invalid file extension: {{data.invalidFileType}}.<br/>Supported file extensions are: {{data.supportedFileTypes}}.",
    "INVALID_FILE_EXTENSION_MOBILE_IMG": "We do not support images in mobile optimized formats (HEIC, HEIF).<br />Please, convert it to JPEG before uploading, or turn this setting off in your camera device and retake photo.",
    "DOCUMENT_STORING_FAILED": "Failed to upload the file, please try again.",
    "SERVER_ERROR": "Server error, please try again.",
    "NO_CONNECTION": "No connectivity, check your internet connection.",
    "DATABASE_ERROR": "Database error, please try again.",
    "DRIVER_PORTAL_ACCESS_FORBIDDEN": "Access forbidden",
    "INVALID_REQUEST": "Incorrect request.",
    "INVITATION_IN_WRONG_STATE": "Invitation is in the wrong state. Probably you already processed it",
    "unknown_validation_error": "Unknown error occured.",
    "mandatory_files_missing": "Please upload required document ",
    "required_document": "Required document",
    "missing": "missing",
    "required": "Required",
    "upload_file": "Upload file",
    "regstep4_documents_lead": "We're legally required to ask you for some documents to sign you up as driver. Documents scans and quality photos are accepted.",
    "no_of_rides": "Number of Rides",
    "driver_vehicle": "Driver vehicle",
    "profile_driver_vehicle_info": "Driver can currently select this vehicle in the app.",
    "driver_vehicle_model": "Model",
    "driver_vehicle_year": "Year",
    "driver_vehicle_seats": "Seats",
    "driver_vehicle_reg_number": "Number plate",
    "username_length_is_invalid": "Username is too short",
    "username_is_too_long": "Username is too long",
    "username_is_mandatory": "Username is mandatory",
    "password_is_mandatory": "Password is mandatory",
    "DRIVER_BLOCKED": "Your account is blocked.",
    "tax_reports": "Tax Reports",
    "tax_reports_info_text": "Here you see list of tax reports for recent months.",
    "tax_report_file_name": "Bolt Tax Report - {{date}}",
    "tax_report_file_name__hopp": "Hopp Tax Report - {{date}}",
    "no_tax_reports": "No tax reports found.",
    "monthly_summaries": "Monthly Summaries",
    "monthly_summaries_info_text": "Here is the list of monthly summaries for recent months.",
    "monthly_summaries_file_name": "Bolt Monthly Summary - {{date}}",
    "monthly_summaries_file_name__hopp": "Hopp Monthly Summary - {{date}}",
    "no_monthly_summaries": "No monthly summaries found.",
    "balance_reports": "Balance Reports",
    "balance_reports_info_text": "Here you see list of balance reports for recent weeks.",
    "balance_report_file_name": "Bolt Balance Report - {{date}}",
    "balance_report_file_name__hopp": "Hopp Balance Report - {{date}}",
    "no_balance_reports": "No balance reports found.",
    "date_period": "Period",
    "EXPIRY_DATE_IS_MANDATORY": "Document expiry date is mandatory. Example: 2017-05-30",
    "INVALID_EXPIRY_DATE": "Invalid expiry date. Document must be valid at least for one day.",
    "car_model_not_in_the_list_email": "info@bolt.eu",
    "car_model_not_in_the_list_email__hopp": "info@gethopp.com",
    "pages": {
      "submitted": {
        "pageTitle": "Already signed up",
        "title": "You have already signed up with this email on {{registrationDate}}",
        "summary": "Someone should contact you in a few days after registration. Please check your inbox for emails from Bolt in case if you have missed anything important regarding your registration.",
        "summary__hopp": "Someone should contact you in a few days after registration. Please check your inbox for emails from Hopp in case if you have missed anything important regarding your registration."
      }
    },
    "auth": {
      "vehicle": {
        "title": "Vehicle - {{model}}",
        "model": "Model",
        "year": "Year",
        "color": "Color",
        "seats": "Seats",
        "phone": "Phone",
        "license_plate": "License Plate",
        "vin": "Vin",
        "transport_license_number": "Transport License Number",
        "transport_license_expires": "Transport License Expires",
        "registration_province": "Province",
        "applicant-comment": "Your comment",
        "admin-comment": "Response to your application",
        "delete-active-vehicle": "Delete active vehicle",
        "documents": {
          "title": "Vehicle Documents"
        },
        "status": {
          "submitted": "In review",
          "resubmitted": "In review",
          "onboarding": "In review",
          "declined": "Declined",
          "declined_silently": "Declined",
          "in_progress": "Draft",
          "needs_resubmission": "Incomplete"
        }
      },
      "vehicles": {
        "title": "Vehicles",
        "title-vehicle-applications": "Vehicle Applications",
        "lead": "Here you see overview of your vehicles.",
        "model": "Model",
        "license_plate": "License Plate",
        "no-vehicles": "No vehicles."
      },
      "vehicle-application": {
        "title": "Vehicle Application for {{model}}",
        "submit-new-application": "To re-submit documents please create a new application.",
        "new-application": "New application",
        "status": {
          "title": "Status",
          "submitted": "In review",
          "onboarding": "On boarding",
          "declined": "Declined",
          "in_progress": "Waiting for documents submission",
          "needs_resubmission": "Needs resubmission",
          "resubmitted": "Resubmitted"
        },
        "documents": {
          "title": "Vehicle Documents",
          "add-document": "Upload file",
          "expires": "Expires",
          "expiry-year": "Year",
          "expiry-month": "Month",
          "expiry-day": "Day",
          "not-required": "This application does not require any additional documents."
        },
        "details": {
          "title": "Vehicle Details"
        },
        "edit-application": "Edit",
        "delete-application": "Delete application",
        "delete-confirmation": {
          "title": "Delete the application?",
          "text": "Are you sure you want to delete this application?",
          "delete": "Delete",
          "cancel": "Cancel"
        },
        "submit-confirmation": {
          "title": "Submit application?",
          "text": "This will replace any previous or existing application(s) that are being reviewed, since a driver can submit only 1 vehicle application.",
          "submit": "Submit",
          "cancel": "Cancel"
        }
      },
      "add-vehicle": {
        "title": "Add new vehicle",
        "form": {
          "car_manufacturer": "Vehicle Manufacturer",
          "car_model": "Model",
          "year": "Year",
          "reg_number": "License Plate",
          "color": "Color",
          "transport-licence-number": "Transport Licence Number",
          "transport-licence-expires": "Transport Licence Expires",
          "province": "Province",
          "vin": "Vin",
          "delete-active-vehicle": "Delete my currently active vehicle",
          "comment": "Comment",
          "model-not-in-list": "If you don't find your vehicle model from list then let us know at {{email}}",
          "driver-block-warning": "Your account will be temporarily blocked until we review your vehicle application.",
          "validation": {
            "model_id.invalid": "Please choose a vehicle model.",
            "model.invalid": "Vehicle model is too long",
            "year": {
              "invalid": "Year is invalid, example: 2016"
            },
            "reg_number": {
              "required": "License plate is required, example: 123ABC",
              "invalid": "License plate is too long"
            },
            "color": {
              "invalid": "Color is invalid, please select one from the list.",
              "required": "Color is required"
            },
            "car_transport_licence_number": {
              "invalid": "Transport licence number is too long",
              "required": "Transport licence number is required"
            },
            "car_transport_licence_expires": {
              "invalid": "Transport licence expiry date is invalid, example: 2017-03-24",
              "required": "Transport licence expiry date is required"
            },
            "vin": {
              "invalid": "Vin is invalid"
            },
            "registration_province_id": {
              "invalid": "Province is invalid, please select another one.",
              "required": "Province is required"
            }
          }
        },
        "application-created": "Check that all the details are correct. If necessary, fill out a new application.",
        "application-declined": "Vehicle application declined",
        "application-in-progress": {
          "title": "Vehicle application hasn’t been submitted",
          "description": "Provide all the information required to add a new vehicle."
        },
        "application-in-review": {
          "title": "Vehicle application is in review",
          "description": "All information provided will be reviewed, wait for activation."
        },
        "application-is-declined": {
          "title": "Vehicle application declined"
        },
        "application-resubmission": {
          "title": "Additional information requested",
          "description": "This vehicle application requires additional information."
        },
        "add-documents": "Upload documents",
        "new-application": "Add another vehicle",
        "view-all": "View all applications"
      },
      "orders": {
        "state": "State",
        "distance": "Distance",
        "booking_fee": "Booking fee",
        "toll_fee": "Toll fee",
        "cancellation_fee": "Cancellation fee",
        "tip": "Tip",
        "total_distance": "Total distance",
        "total_booking_fee": "Total booking fee",
        "total_toll_fee": "Total toll fee",
        "total_cancellation_fee": "Total cancellation fee",
        "payment": "Payment",
        "price": "Price",
        "order_in_price_review": "Waiting for fare review",
        "currency": "Currency",
        "status": {
          "client_cancelled": "Client cancelled",
          "driver_rejected": "Driver rejected",
          "client_did_not_show": "Client did not show up",
          "finished": "Finished"
        }
      },
      "pagination": {
        "page": "Page"
      },
      "balance-reports": {
        "show-more": "Show more"
      },
      "profile": {
        "custom_fields": {
          "ng": {
            "account_iban": {
              "field_name": "Nuban Account Number",
              "hint": "1234567890",
              "description": "Your 10 digits Nigerian Bank Account Number"
            },
            "account_swift": {
              "field_name": "Bank Name",
              "description": "Select your Nigerian Bank"
            },
            "account_recipient": {
              "field_name": "Bank Account Holder Name",
              "hint": "ABC Taxis Ltd / John Doe",
              "description": "Bank account holder name, person or company"
            }
          },
          "gh": {
            "account_iban": {
              "field_name": "Mobile Wallet Number",
              "description": "Your Ghanan Mobile Wallet Number",
              "hint": "233123456789"
            },
            "account_swift": {
              "field_name": "Mobile Money Network",
              "description": "Select your Ghanan Mobile Money Network"
            },
            "account_recipient": {
              "field_name": "Mobile Money Wallet Holder Name",
              "hint": "ABC Taxis Ltd / John Doe",
              "description": "Mobile Money Wallet holder name, person or company"
            }
          },
          "ke": {
            "account_iban": {
              "field_name": "Mobile Wallet Number",
              "description": "Your Kenyan Mobile Wallet Number",
              "hint": "254123456789"
            },
            "account_swift": {
              "field_name": "Mobile Money Network",
              "description": "Select your Kenyan Mobile Money Network"
            },
            "account_recipient": {
              "field_name": "Mobile Money Wallet Holder Name",
              "hint": "ABC Taxis Ltd / John Doe",
              "description": "Mobile Money Wallet holder name, person or company"
            }
          },
          "tz": {
            "account_iban": {
              "field_name": "Mobile Wallet Number",
              "description": "Your Tanzanian Mobile Wallet Number",
              "hint": "255712345678"
            },
            "account_swift": {
              "field_name": "Mobile Money Network",
              "description": "Select your Tanzanian Mobile Money Network"
            },
            "account_recipient": {
              "field_name": "Mobile Money Wallet Holder Name",
              "hint": "ABC Taxis Ltd / John Doe",
              "description": "Mobile Money Wallet holder name, person or company"
            }
          },
          "ug": {
            "account_iban": {
              "field_name": "Mobile Wallet Number",
              "description": "Your Ugandan Mobile Wallet Number",
              "hint": "256712345678"
            },
            "account_swift": {
              "field_name": "Mobile Money Network",
              "description": "Select your Ugandan Mobile Money Network"
            },
            "account_recipient": {
              "field_name": "Mobile Money Wallet Holder Name",
              "hint": "ABC Taxis Ltd / John Doe",
              "description": "Mobile Money Wallet holder name, person or company"
            }
          }
        },
        "confirm": {
          "sent_verification_code_message": "We've sent a verification code to your phone. Enter the code below to confirm your changes.",
          "resent_verification_code_message": "We've sent you a new verification code to your phone.",
          "verification_code_invalid": "Verification code is invalid.",
          "cancel_changes_confirmation": "Your profile changes will be lost, are you sure?",
          "form": {
            "confirm": "Confirm",
            "confirming": "Confirming...",
            "resend": "Resend",
            "resending": "Resending...",
            "cancel": "Cancel"
          }
        },
        "fields": {
          "account_type": {
            "unknown": "Unknown"
          }
        },
        "validation_errors": {
          "email": {
            "invalid": "Email is not valid.",
            "not_unique": "Email is already taken, please enter another one.",
            "empty": "Email is mandatory."
          },
          "phone": {
            "invalid": "Please enter mobile phone number, including country code. Example: +1 256 893 9203",
            "not_unique": "Phone number is already taken, please enter another one.",
            "invalid_dynamic": "Please insert the phone number in the international format. Enter the prefix with the \"+\" and then enter the phone number. For example {{phone}}"
          },
          "company_email": {
            "invalid": "Company email is not a valid email."
          },
          "address": {
            "is_required": "Address is mandatory."
          },
          "legal_type": {
            "is_required": "Legal type is mandatory.",
            "invalid": "Legal type has invalid type."
          },
          "account_iban": {
            "invalid": "Account IBAN is invalid.",
            "could_not_be_parsed": "Account number(IBAN) could not be parsed",
            "only_eight_digits": "Add a valid 8 digits long Bank Account Number (Only numbers allowed)",
            "ban_only_numbers": "Add a valid Bank Account Number (Only numbers allowed)",
            "clabe_could_not_be_parsed": "Bank identifier value(CLABE) could not be parsed",
            "mmwn_should_start_with_255": "Mobile Money Wallet Number should start with Country Code 255",
            "mmwn_should_start_with_233": "Mobile Money Wallet Number should start with Country Code 233",
            "mmwn_should_start_with_254": "Mobile Money Wallet Number should start with Country Code 254",
            "mmwn_should_start_with_256": "Mobile Money Wallet Number should start with Country Code 256",
            "cz_account_identifier_expected": "Czech account identifier(IBAN) is expected",
            "last_4_digits_of_credit_card": "4 last digits of the credit card expected(for individual drivers)"
          },
          "account_type": {
            "invalid": "Account type has invalid type."
          },
          "invoice_issuer_name": {
            "is_required": "Invoice issuer name is mandatory."
          },
          "account_recipient": {
            "up_to_32_letters_or_digits": "Value should contain up to 32 english letters or digits",
            "up_to_32_letters_or_digits_no_dots": "Value should contain up to 32 english letters or digits (dots are not allowed)",
            "only_letters_or_digits": "Value can only contain english letters and digits",
            "up_to_32_letters": "Value should contain up to 32 english letters",
            "only_cyrillic": "Value can only contain cyrillic letters",
            "no_longer_than_16_chars": "Value could not be longer then 16 characters",
            "up_to_32_letters_for_individuals": "Value should contain up to 32 english letters(for individual drivers)",
            "not_latin_characters": "Only Latin characters are allowed"
          },
          "account_swift": {
            "select_valid_mmn_ug": "Please select a valid Mobile Money Network for UG",
            "sort_code_6_digits": "Add a valid 6 digits long Sort Code (Only numbers allowed)",
            "select_valid_mmn_gh": "Please select a valid Mobile Money Network for GH",
            "select_valid_mmn_ke": "Please select a valid Mobile Money Network for KE",
            "select_valid_mmn_tz": "Please select a valid Mobile Money Network for TZ",
            "select_valid_bank_ng": "Please select a valid Bank for NG",
            "select_valid_bank_py": "Please select a valid Bank for PY",
            "bi_not_valid": "Bank identifier value is not valid",
            "phone_number_expected": "Phone number registered on bank account is expected. Should start from '38'(for individual drivers)"
          },
          "vat_code": {
            "format_invalid": "VAT code format is not valid",
            "account_number_required": "Account number is required for VAT code",
            "value_not_valid": "Vat code value is not valid"
          },
          "account_branch_code": {
            "only_letters_and_digits": "Value can only contain english letters and digits",
            "no_more_than_6_chars": "Value should contain no more then 6 characters"
          },
          "company_is_liable_to_vat": {
            "should_be_marked": "Company should be marked as liable to VAT"
          },
          "birthday": {
            "invalid": "Date of Birth is invalid"
          },
          "form_of_work": {
            "not_in_enum": "Please select a valid Form of work",
            "is_required": "Please select a valid Form of work"
          },
          "contract_number": {
            "invalid": "Contract number must be at least one character long"
          }
        }
      },
      "plate-check": {
        "already-active-error": "This is your active vehicle's license plate.",
        "in-review-error": "Vehicle with this license plate is in review, please wait for activation.",
        "existing-applications-explainer": "You can find all your active vehicles and vehicle application in the {{{vehiclesSection}}}"
      },
      "consent": {
        "eu_tos_text": "I hereby commit to only offer products and services on the Bolt platform which comply with the applicable national and EU regulations.",
        "agree": "Agree",
        "already-agreed": "Already agreed",
        "close": "Close",
        "logo": "Logo"
      }
    },
    "form": {
      "button": {
        "next": "Next",
        "continue": "Continue",
        "save": "Save",
        "saving": "Saving..."
      }
    },
    "registration": {
      "documents": {
        "ca": {
          "vehicle-requirements": "Vehicle Requirements",
          "driver-requirements": "Driver Requirements",
          "document-requirements": "Click here for more info on Document requirements",
          "safety-certificate": "How to get your Safety Standards Certificate"
        }
      }
    },
    "login-route": {
      "missingCredentials": "Please enter your account details.",
      "tooManyFailedAttempts": "You have recently failed too many login attempts, please try again later."
    },
    "magic-login": {
      "email": "Email",
      "emailPlaceholder": "Enter your email",
      "emailIsMandatory": "Please enter a valid email.",
      "sendMagicLink": "Send magic link",
      "loginWithMagicLink": "Log in with Magic Link",
      "newLoginWithMagicLink": "Email me a login link",
      "magicLinkEmailSent": "We have sent a magic link to your email {{{email}}}.",
      "tokenExpired": "Hey, this magic link has expired!"
    },
    "verification": {
      "codeMissing": "Please enter your verification code",
      "codeResent": "Your verification code has been resent",
      "codeResentByCall": "Your will receive automated call with verification code",
      "codeLabel": "We have sent a verification code to {{{phone}}}. Please enter it below.",
      "codePlaceholder": "Your verification code",
      "codeTooYoung": "Please wait before requesting a new code.",
      "resendingCode": "Resending...",
      "resendCodeSMS": "Resend code",
      "resendCodeCall": "Send code with automated phone call",
      "incorrectCode": "You have entered an incorrect code",
      "sendLimitReached": "You have requested too many codes, please try again later",
      "codeNotSent": "There was an issue with sending your code, please try again later",
      "codeExpired": "The entered code has expired, please request a new code",
      "attemptsLimitReached": "You have requested too many verification codes in a short time period, please try again later",
      "loggingIn": "You are being logged in, please wait...",
      "smsCodeNotFound": "Incorrect code.",
      "didNotGetTheCode": "Did not get the code?",
      "resendToTryAgain": "Resend code to try again.",
      "tryToResendCodeOr": "Try to resend the code or",
      "enterCodeWithLength": "Please enter {{{codeLength}}}-digit code:",
      "loginWithUsernameAndPassword": "login with username and password"
    },
    "countries": {
      "ee": "Estonia",
      "za": "South Africa",
      "lv": "Latvia",
      "ng": "Nigeria",
      "ge": "Georgia",
      "lt": "Lithuania",
      "mx": "Mexico",
      "hu": "Hungary",
      "az": "Azerbaijan",
      "ro": "Romania",
      "cz": "Czech Republic",
      "eg": "Egypt",
      "sk": "Slovakia",
      "pl": "Poland",
      "ua": "Ukraine",
      "rs": "Serbia",
      "fi": "Finland",
      "ad": "Andorra",
      "ae": "United Arab Emirates",
      "af": "Afghanistan",
      "ag": "Antigua and Barbuda",
      "ai": "Anguilla",
      "al": "Albania",
      "am": "Armenia",
      "an": "Netherlands Antilles",
      "ao": "Angola",
      "aq": "Antarctica",
      "ar": "Argentina",
      "as": "American Samoa",
      "at": "Austria",
      "au": "Australia",
      "aw": "Aruba",
      "ba": "Bosnia and Herzegovina",
      "bb": "Barbados",
      "bd": "Bangladesh",
      "be": "Belgium",
      "bf": "Burkina Faso",
      "bg": "Bulgaria",
      "bh": "Bahrain",
      "bi": "Burundi",
      "bj": "Benin",
      "bl": "Saint-Barthélemy",
      "bm": "Bermuda",
      "bn": "Brunei",
      "bo": "Bolivia",
      "br": "Brazil",
      "bs": "Bahamas",
      "bt": "Bhutan",
      "by": "Belarus",
      "bw": "Botswana",
      "bz": "Belize",
      "ca": "Canada",
      "cc": "Cocos Islands",
      "cd": "Democratic Republic of the Congo",
      "cf": "Central African Republic",
      "cg": "Republic of the Congo",
      "ch": "Switzerland",
      "ci": "Ivory Coast",
      "ck": "Cook Islands",
      "cl": "Chile",
      "cm": "Cameroon",
      "cn": "China",
      "co": "Colombia",
      "cr": "Costa Rica",
      "cu": "Cuba",
      "cv": "Cape Verde",
      "cw": "Curaçao",
      "cx": "Christmas Island",
      "cy": "Cyprus",
      "de": "Germany",
      "dj": "Dijbouti",
      "dk": "Denmark",
      "dm": "Dominica",
      "do": "Dominican Republic",
      "dz": "Algeria",
      "ec": "Ecuador",
      "eh": "Western Sahara",
      "er": "Eritrea",
      "es": "Spain",
      "et": "Ethiopia",
      "fj": "Fiji",
      "fk": "Falkland Islands",
      "fm": "Micronesia",
      "fo": "Faroe Islands",
      "fr": "France",
      "ga": "Gabon",
      "gb": "United Kingdom",
      "gd": "Grenada",
      "gg": "Guernsey",
      "gh": "Ghana",
      "gi": "Gibraltar",
      "gl": "Greenland",
      "gm": "Gambia",
      "gn": "Guinea",
      "gp": "Guadeloupe",
      "gq": "Equatorial Guinea",
      "gr": "Greece",
      "gt": "Guatemala",
      "gu": "Guam",
      "gw": "Guinea-Bissau",
      "gy": "Guyana",
      "hk": "Hong Kong",
      "hn": "Honduras",
      "hr": "Croatia",
      "ht": "Haiti",
      "id": "Indonesia",
      "ie": "Ireland",
      "il": "Israel",
      "im": "Isle of Man",
      "in": "India",
      "io": "British Indian Ocean Territory",
      "iq": "Iraq",
      "ir": "Iran",
      "is": "Iceland",
      "it": "Italy",
      "je": "Jersey",
      "jm": "Jamaica",
      "jo": "Jordan",
      "jp": "Japan",
      "ke": "Kenya",
      "kg": "Kyrgyzstan",
      "kh": "Cambodia",
      "ki": "Kiribati",
      "km": "Comoros",
      "kn": "Saint Kitts and Nevis",
      "kp": "North Korea",
      "kr": "South Korea",
      "kw": "Kuwait",
      "ky": "Cayman Islands",
      "kz": "Kazakhstan",
      "la": "Laos",
      "lb": "Lebanon",
      "lc": "Saint Lucia",
      "li": "Liechtenstein",
      "lk": "Sri Lanka",
      "lr": "Liberia",
      "ls": "Lesotho",
      "lu": "Luxembourg",
      "ly": "Libya",
      "ma": "Morocco",
      "mc": "Monaco",
      "md": "Moldova",
      "me": "Montenegro",
      "mf": "Saint Martin",
      "mg": "Madagascar",
      "mh": "Marshall Islands",
      "mk": "Macedonia",
      "ml": "Mali",
      "mm": "Myanmar",
      "mn": "Mongolia",
      "mo": "Macau",
      "mp": "Northern Mariana Islands",
      "mq": "Martinique",
      "mr": "Mauritania",
      "ms": "Montserrat",
      "mt": "Malta",
      "mu": "Mauritius",
      "mv": "Maldives",
      "mw": "Malawi",
      "my": "Malaysia",
      "mz": "Mozambique",
      "na": "Namibia",
      "nc": "New Caledonia",
      "ne": "Niger",
      "ni": "Nicaragua",
      "nl": "Netherlands",
      "no": "Norway",
      "np": "Nepal",
      "nr": "Nauru",
      "nu": "Niue",
      "nz": "New Zealand",
      "om": "Oman",
      "pa": "Panama",
      "pe": "Peru",
      "pf": "French Polynesia",
      "pg": "Papua New Guinea",
      "ph": "Philippines",
      "pk": "Pakistan",
      "pm": "Saint Pierre and Miquelon",
      "pn": "Pitcairn",
      "pr": "Puerto Rico",
      "ps": "Palestine",
      "pt": "Portugal",
      "pw": "Palau",
      "py": "Paraguay",
      "qa": "Qatar",
      "re": "Reunion",
      "ru": "Russia",
      "rw": "Rwanda",
      "sa": "Saudi Arabia",
      "sb": "Solomon Islands",
      "sc": "Seychelles",
      "sd": "Sudan",
      "se": "Sweden",
      "sg": "Singapore",
      "sh": "Saint Helena",
      "si": "Slovenia",
      "sj": "Svalbard and Jan Mayen",
      "sl": "Sierra Leone",
      "sm": "San Marino",
      "sn": "Senegal",
      "so": "Somalia",
      "sr": "Suriname",
      "ss": "South Sudan",
      "st": "São Tomé and Príncipe",
      "sv": "El Salvador",
      "sx": "Sint Maarten",
      "sy": "Syria",
      "sz": "Swaziland",
      "tc": "Turks and Caicos Islands",
      "td": "Chad",
      "tg": "Togo",
      "th": "Thailand",
      "tj": "Tajikistan",
      "tk": "Tokelau",
      "tl": "East Timor",
      "tm": "Turkmenistan",
      "tn": "Tunisia",
      "to": "Tonga",
      "tr": "Turkey",
      "tt": "Trinidad and Tobago",
      "tv": "Tuvalu",
      "tw": "Taiwan",
      "tz": "Tanzania",
      "ug": "Uganda",
      "us": "United States",
      "uy": "Uruguay",
      "uz": "Uzbekistan",
      "va": "Vatican",
      "vc": "Saint Vincent and the Grenadines",
      "ve": "Venezuela",
      "vg": "British Virgin Islands",
      "vi": "U.S. Virgin Islands",
      "vn": "Vietnam",
      "vu": "Vanuatu",
      "wf": "Wallis and Futuna",
      "ws": "Samoa",
      "xk": "Kosovo",
      "ye": "Yemen",
      "yt": "Mayotte",
      "zm": "Zambia",
      "zw": "Zimbabwe"
    },
    "circle_k": {
      "feature_not_available": "Feature not available",
      "feature_not_available_explainer": "Circle K integration is not available yet, please get back later",
      "title": "Circle K",
      "privacy_policy": "Privacy policy",
      "privacy_policy_explainer": "To start the registration process, we are required to share your personal information with Circle K.",
      "privacy_policy_agree": "Agree",
      "privacy_policy_cancel": "Cancel",
      "registration_completed": "Registration complete",
      "registration_completed_explainer": "You’ve successfully signed up for the Circle K loyalty programme and can start getting your benefits! You can access the programme from the <b>Campaigns page</b>.",
      "registration_completed_close": "Close",
      "contact_customer_support": "Please contact customer support info@bolt.eu",
      "contact_customer_support__hopp": "Please contact customer support info@gethopp.com"
    },
    "vat_status": {
      "title": "VAT Status Confirmation",
      "waiting": {
        "header": "VAT compliance requirement",
        "explanation": "Please notify us (Bolt Operations OU, a non-resident entity) about your business status as per Section 17(3) of the VAT (Digital Marketplace Supply) Regulations, 2020.",
        "explanation__hopp": "Please notify us (Hopp Operations, a non-resident entity) about your business status as per Section 17(3) of the VAT (Digital Marketplace Supply) Regulations, 2020.",
        "question": "Do you confirm that by providing transportation services to fare paying passengers Bolt Operations OU (which is a non-resident entity) should not charge VAT on commissions or other fees invoiced to you?",
        "question__hopp": "Do you confirm that by providing transportation services to fare paying passengers Hopp Operations (which is a non-resident entity) should not charge VAT on commissions or other fees invoiced to you?",
        "confirm": "Confirm"
      },
      "submitted": {
        "header": "Thank you for notifying us",
        "explanation": "Sharing this information will help both you and Bolt to remain compliant with the local tax regulations and to continue earning on the platform. This notification is a sufficient notification to Bolt Operations OU as per Paragraph 17 (3) of the VAT (Digital Marketplace Supply) Regulations, 2020.",
        "explanation__hopp": "Sharing this information will help both you and Hopp to remain compliant with the local tax regulations and to continue earning on the platform. This notification is a sufficient notification to Hopp Operations as per Paragraph 17 (3) of the VAT (Digital Marketplace Supply) Regulations, 2020.",
        "signature": "Your friends at Bolt. 💚",
        "signature__hopp": "Your friends at Hopp. 💚"
      },
      "errors": {
        "load_error": "Error",
        "submit_error": "Could not submit confirmation",
        "try_again": "Please try again later"
      }
    },
    "in_app_signup": {
      "verification_header": "Enter verification code",
      "verification_subheader_with_length": "Please enter the {{{codeLength}}}-digit code we sent via text to",
      "verification_code_label": "Verification code",
      "verification_code_placeholder": "Enter code",
      "verify": "Verify",
      "resend_code_in": "Resend code in ",
      "resend_code": "Resend code",
      "code_required": "Code is required",
      "verified_error_header": "Could not complete sign up",
      "start_new_registration": "Start new registration",
      "SERVER_ERROR": "Something went wrong. Please request a new code"
    },
    "car_rental_payment_dispute": {
      "open_dispute": "Open dispute",
      "dispute_success": "You have successfully opened the dispute.",
      "success_text": "You can now close this window.",
      "dispute_error": "Failed to open the dispute.",
      "error_text_duplicate": "This dispute has been already opened.",
      "error_text_expired": "Time to open dispute has expired.",
      "error_text_fallback": "Please contact our Customer Support team for assistance."
    },
    "fleet-invitation": {
      "title": "Joining the fleet",
      "join_fleet_warn": "You're about to join fleet <b>{{fleet}}</b>, please confirm",
      "accept_invitation": "Join",
      "decline_invitation": "Decline",
      "back_to_home_screen": "Back to home screen",
      "accepted_confirmation": "You successfully accepted the invitation to join <b>{{fleet}}</b>. You will be notified separately with information about next steps",
      "declined_confirmation": "You successfully declined the invitation to join <b>{{fleet}}</b>. <b>{{fleet}}</b> will be notified about your decision"
    },
    "form_of_work": {
      "label": "Form of work",
      "license_owner": "I am the owner of the alternative transport license",
      "employee_of_a_fleet": "I work for the alternative transport license owner",
      "description": "Form of work based on local regulations"
    },
    "contract_number": {
      "label": "Contract number",
      "description": "Number of the contract signed with the alternative transport license owner"
    },
    "internal_redirect": {
      "redirect_message": "Taking you back to"
    }
  };
  _exports.default = _default;
});